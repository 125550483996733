<template>
  <div>
    <Edit
      :cidades="cidades"
      :item="currentItem"
      :update="updateItem"
      :close="closeEdit"
    ></Edit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <div class="filters row" style="margin-top: 0">
          <div class="col-1-3">
            <span class="filters__search">
              <input
                type="text"
                v-model="filtros.termo"
                placeholder="Buscar por..."
                v-on:input="debounceloadTransportadoras()"
              />
              <img
                style="position: relative; right: -10px"
                src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
              />
            </span>
          </div>
        </div>

        <div class="table-responsive">
          <datatable
            :columns="table.columns"
            :data="items"
            :filter-by="filtros.termo"
            class="table"
          >
            <template slot-scope="{ row }">
              <tr>
                <td class="highlight" style="text-align: center">{{ row.id }}</td>
                <td style="text-align: left">{{ row.razSoc }}</td>
                <td class="actions">
                  <button
                    type="button"
                    class="button button-primary"
                    v-on:click="openEdit(row)"
                  >
                    <fa-icon icon="pencil-alt" />
                  </button>
                </td>
              </tr>
            </template>
          </datatable>
        </div>
        <pagination
          :data="table.paginator"
          :limit="2"
          @pagination-change-page="loadTransportadoras"
        ></pagination>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from "just-clone";
import _debounce from "lodash/debounce";
import _map from "lodash/map";
import { get, put } from "../../helpers/apiRequest";
import Edit from "./Edit";
import {loadDescs} from "../../helpers/utils";

export default {
  name: "condicaoEntrega",
  components: {
    Edit,
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      loading: false,
      items: [],
      filtros: {
        termo: "",
        colunaId: "id",
        order: "asc",
      },
      currentItem: {},
      cidades: [],
      table: {
        filter: "",
        columns: [
          {label: 'ID', field: 'id', align: 'center', sortable: false},
          {label: 'Transportadora', field: 'razaosocial', sortable: false},
          {label: '', field: '', sortable: false},
        ],
        paginator: {},
      },
    };
  },

  methods: {
    loadDescricaoModulo() {
      const {descSingular, descPlural} = loadDescs('ADMIN_ENTREGA');
      this.descSingular = descSingular;
      this.descPlural   = descPlural;
    },
    loadTransportadoras(page = 1, take = 12) {
      get(`/admin/condicoes-entrega?take=${take}&page=${page}&q=${this.filtros.termo}`)
          .then((json) => {
            this.items           = json.itens;
            this.table.paginator = json.paginator;
            this.loading         = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    debounceloadTransportadoras: _debounce(function () {
      this.loadTransportadoras();
    }, 300),
    openEdit(item) {
      this.currentItem = clone(item);
      this.cidades     = item.cidades;
      this.$modal.show("editCondicaoEntrega");
    },
    closeEdit() {
      this.$modal.hide("editCondicaoEntrega");
    },
    updateItem(take = 12) {
      const { current_page } = this.table.paginator;

      this.loading = true;

      const item    = { ...this.currentItem };
      const payload = {
        'cidades': _map(item.cidades, 'id'),
        'page': current_page,
        'take': take
      };

      const request = put(
        `/admin/condicoes-entrega/transportadora/${item.id}`, payload
      );
      request
        .then((json) => {
          this.$swal({
            title: "Sucesso!",
            text: "Transportadora atualizada com sucesso!",
            icon: "success",
          });
          this.loading = false;
          this.loadTransportadoras(current_page, take);
          this.closeEdit();
        })
        .catch(() => {
          this.loading = false;
        });
      return request;
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadDescricaoModulo();
    this.loadTransportadoras();
  },
};
</script>
