<template>
  <modal
    name="editCondicaoEntrega"
    height="auto"
    width="900"
    :scrollable="true"
    @before-open="beforeOpen()"
    :clickToClose="false"
  >
    <CloseModal :close="close"/>
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Cidades" v-if="item.id">
            <div class="xrow">
              <div class="col-1-1">
                <div class="col-1-2">
                  <span class="clientes filters__search">
                    <input
                      type="text"
                      v-model="filter"
                      v-on:input="debounceloadCidades()"
                      placeholder="Filtrar por UF, cidade ou IBGE"
                    />
                    <img
                      src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                    />
                  </span>
                </div>
              </div>
              <div class="col-1-2">
                <div class="scrollable lista-cidades" style="height: 600px;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                      <tr>
                        <th colspan="1">Cidades</th>
                        <th colspan="1">
                          <div class="actions">
                            <button
                                v-on:click="addTodasCidades()"
                                class="button button-success"
                                :disabled="cidadesJaAdicionadas()"
                            >
                              <svg
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 42 42"
                                  style="width: 12px; height: 12px; fill: rgb(255, 255, 255);"
                              >
                                <path
                                    d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"
                                />
                              </svg>
                            </button>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="cidade in cidades" v-bind:key="cidade.id">
                        <td colspan="3">
                          <div class="line">
                            <div>
                                <span class="pre-text">{{ cidade.cidade }} / {{ cidade.uf }}</span>
                            </div>
                            <div class="actions">
                              <button
                                v-on:click="addCidade(cidade)"
                                class="button button-success"
                                :disabled="cidadeJaAdicionada(cidade.id)"
                              >
                                <svg
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 42 42"
                                  style="width: 12px; height: 12px; fill: rgb(255, 255, 255);"
                                >
                                  <path
                                    d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-1-2">
                <div class="scrollable lista-cidades" style="height: 600px">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                      <tr>
                        <th colspan="1">Cidades Vinculadas</th>
                        <th colspan="1">
                          <div class="actions">
                                <button
                                    type="button"
                                    name="button"
                                    v-on:click="remTodasCidades()"
                                    class="button button-error"
                                >
                                  <fa-icon icon="trash-alt"/>
                                </button>
                            </div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="cidade in item.cidades"
                        v-bind:key="cidade.id"
                      >
                        <td colspan="3">
                          <div class="line">
                            <div>
                              <span class="pre-text">{{ cidade.cidade }} / {{ cidade.uf }}</span>
                            </div>
                            <span class="actions">
                                <button
                                  type="button"
                                  name="button"
                                  v-on:click="remCidade(cidade.id)"
                                  class="button button-error"
                                >
                                  <fa-icon icon="trash-alt"/>
                                </button>
                            </span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <CButtonToolbar>
            <button
              :class="{
                'button button-success': true,
                'button-disabled': false,
              }"
              v-on:click="handleSubmit()"
            >Alterar
            </button>
            <button class="button" v-on:click="close">Fechar</button>
          </CButtonToolbar>
      </div>
    </div>
  </modal>
</template>
<script>
import {get} from "../../helpers/apiRequest";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _reject from "lodash/reject";
import _sortBy from "lodash/sortBy";
import _differenceBy from "lodash/differenceBy";
import CloseModal from "../../components/CloseModal";

export default {
  name: "editCondicaoEntrega",
  components: {
    CloseModal,
  },
  props: [
    "update",
    "close",
    "item",
  ],
  data() {
    return {
      filter: "",
      cidades: [],
      cidadesAdicionadas: [],
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    beforeOpen()
    {
      this.loadCidades();
      this.sortCidades();
    },
    cidadesDisponiveis()
    {
      return _differenceBy(this.cidades, this.item.cidades, 'id');
    },
    sortCidades(coluna = 'cidade') {
      this.item.cidades = _sortBy(this.item.cidades, ['uf', 'cidade']);
    },
    addCidade(cidade, sort = true) {
      this.item.cidades.push(cidade);
      if(sort) {
        this.sortCidades();
      }
    },
    addTodasCidades() {
      let cidades = this.cidadesDisponiveis();

      for(let cidade of cidades) {
        this.addCidade(cidade, false);
      }
      this.sortCidades();
    },
    cidadeJaAdicionada(idCidade) {
      return _find(this.item.cidades, {id: idCidade});
    },
    cidadesJaAdicionadas() {
      let cidades = this.cidadesDisponiveis();
      return cidades.length === 0;
    },
    remCidade(idCidade) {
      this.item.cidades = _reject(this.item.cidades, {id: idCidade})
    },
    remTodasCidades() {
      this.item.cidades = [];
    },
    loadCidades(take = 50) {
      get(`/admin/cidades?take=${take}&q=${this.filter}`)
        .then((json) => {
          this.cidades = json.itens;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    debounceloadCidades: _debounce(function () {
      this.loadCidades();
    }, 300),
    handleSubmit() {
        let request;

        if (!!this.item.id) {
          request = this.update();
        }

        request.catch((error) => {
          this.$swal({
            title: "Ops!",
            text: error.message,
            icon: "error",
          });
        });
    },
  }
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pre-text {
  font-size: 10px;
}

</style>
